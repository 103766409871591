.bigPicture {
  width: 100%;
  height: 'auto';
  box-sizing: border-box;
  border: 1px solid #ddd;
  box-shadow: -2px 1px 5px 1px #eee;
}

.thumbs {
  width: 100%;
  min-width: 50px;
  height: auto;
  box-sizing: border-box;
  border: 1px solid #ddd;
  box-shadow: -2px 1px 5px 1px #eee;
  margin-bottom: 20px;
  &:hover {
    cursor: pointer;
  }
}

.addonImg {
  width: 130px;
  display: inline-block;
  float: right;
  margin: 4px;
  padding: 4px;
}
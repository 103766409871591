:root {
  --font-size-base: 16px;
  --font-size-h1: 28px;
  --font-size-h2: 24px;
  --font-size-h3: 20px;
  --font-size-h4: 16px;
  --font-size-h5: 14px;

  --color1: #002d04; 
  --color2: #0cb577;
  --color3: #014d4e;
}

/* #0CB577
moraceous dark teal
#014D4E
multiscreen dark forest green
#002D04 */

.text {
  color: #002d04;
}

.bg {
  color: #0cb577;
}

.accent {
  color: #014d4e;
}

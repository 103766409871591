.form-check-input {
  margin: 0 !important;
  position: relative !important;
}

a {
  color: #002d04 !important;
}

a:hover {
   color: #0cb577 !important;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

*:focus {
  outline: none;
}

/*
input, input:focus{
  outline:0 !important;
  -webkit-appearance:none !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.form-control:focus {
 outline: 0px;
 border: 0px;
}

input {
  border: #ddd 1px solid !important;
}

input:focus {
  border: #ddd 1px solid !important;
}

input[type='checkbox'] {
  display: relative;
}
*/
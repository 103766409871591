.stripeContainer {
  width: 100%;
  display:block;
}

.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  -webkit-box-shadow: 0 1px 3px 0 #e6ebf1;

          box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  -webkit-transition: -webkit-box-shadow 150ms ease;
  transition: -webkit-box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease, -webkit-box-shadow 150ms ease;
}

.StripeElement--focus {
  -webkit-box-shadow: 0 1px 3px 0 #cfd7df;
          box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}


.wrapper {
  min-height: 225px;
  max-height: 225px;
  height: 225px;
  box-sizing: border-box;
  position: relative;
  padding: 5px;
  &:hover {
    height: 225px;
    padding: 4px;
    border: 1px solid #ddd;
    box-shadow: -2px 1px 5px 1px #eee;
  }
}

.ratingCount {
  font-size: 12px;
}
.login {
  margin: 0 auto;
  width: 400px;
  border:1px solid #ddd;
  padding: 5px;
}

.img {
  padding: 0;
  margin: 0;
}

.forgot {
  float: right;
  top: 15px;
  position: relative;
}

.signUpBtn {
  color: #fff;
  background-color: #000;
}
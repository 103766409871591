.wrapper {
  width: 100%;
  border: 1px solid #000;
  overflow: auto;
}

.image {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.myHoverWrapper {
  overflow-x:hidden;
  overflow-y: auto;
  position: absolute;
  border: 1px solid #ddd;
  z-index: 101;
  background-color: #fff;
  box-shadow: -2px 1px 5px 1px #ddd;
  top: 35px;
  right: 0;
  width: 250px;
  max-height: 300px;
}
@import '@csstools/normalize.css';
@import './variables.css';
@import url('https://fonts.googleapis.com/css?family=Lora:400,700|Titillium+Web:400,700');
/* @import-normalize; */

:global(body) {
  font-family: 'Titillium Web', Arial, Sans-Serif;
  font-size: var(--font-size-base);
}

.wrapper {
  margin: auto;
  max-width: 100%;
  min-height: 600px;
  h1 {
    font-size: var(--font-size-h1);
  }
  font-family: Avenir-Book,HelveticaNeue,Helvetica Neue,Helvetica,Arial,sans-serif;
}

.reactLogo {
  height: 48px;
  vertical-align: middle;
  width: 48px;
}
.spacer {
  margin-top: 20px;
}

.hover-pointer:hover {
    cursor: pointer;
  }

.sm-margin {
  margin-top: 10px;
}

.xs-hidden {
  display: inline-block;
}

@media only screen and (max-width: 575px) {

.xs-hidden {
    display: none
}
  }

.sm-hidden {
  display: inline-block;
}

@media only screen and (max-width: 685px) {

.sm-hidden {
    display: none
}
  }

.xs-only {
  display: none !important;
}

@media only screen and (max-width: 575px) {

.xs-only {
    display: contents !important
}
  }

.xs-spacer {
  display: none;
}

@media only screen and (max-width: 575px) {

.xs-spacer {
    display: block;
    margin-top: 30px;
    border-top: 1px solid #ccc;
    height: 45px;
    width: 100%;
    content: ' '
}
  }

/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {
}

.text-left {
  text-align: left;
}

.border-bottom {
  border-bottom: 1px solid #aaa;
  padding-bottom: 5px;
}

.border {
  border-bottom: 1px solid #aaa;
  padding: 5px;
}
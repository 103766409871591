.accountPopover {
  text-align: right;
  position: absolute;
  z-index: 10;
  width: 200px;
  min-height: 100px;
  border: 1px solid #ccc;
  background-color: #fff;
  top: 35px;
  font-size: 14px;
  right: -35px;
  
}

.accountItem {
  height: 25px;
  &:hover {
    background-color: #eee;
  }
  a {
    padding: 5px;
  }
}

.menuWrapper {
  float: right;
  margin-right: 20px;
  top: 10px;
  position: relative;
}

.faIcon {
  color: rgba(0,0,0,.5);
}

.menu {
  font-family: Arial,sans-serif !important;
  text-transform: uppercase;
  font-size: 12px;
  position: relative;
  display: inline-block;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom:10px;
  height: 35px;

  &:hover {
    padding-left: 9px;
    padding-right: 9px;
    border-left: 1px #ddd solid;
    border-right: 1px #ddd solid;
  }
}

.title {
  padding-top: 5px;
  display: inline-block;
}

.headerLink {
  margin-right: 50px;
  float: right;
  position: relative;
  top: 5px;
  font-size: 16px;
  color: #002d04;

  height: 36px;
  padding: 5px;
  box-sizing: border-box;
  display: inline-block;
}

.countLabel {
  position: relative;
  top: -3px;
  margin-left: 6px;
  font-size: 14px;
  display: inline-block;
}

.bagCount {
  display: inline-block;
  left: 20px;
  top: -10px;
  position: absolute;
  background-color: #ccc;
  border-radius: 15px;
  width: 20px;
  height: 20px;
}

.myBag {
  margin-left: 15px;
  position: relative;
  display: inline;
}

.myAccount {
  position: relative;
  display: inline;
  top: 3px;
}

.headerWrapper {
  padding-top: 20px;
  height: auto;
  border-bottom: 1px solid #ccc;
  margin-bottom: 24px;
}

.subCategory {
  position: absolute;
  margin-top: 11px;
  margin-left: -10px;
  width: 200px;
  min-height: 100px;

  background-color: #fff;
  border-top: 1px solid #ccc;
  box-shadow: -2px 1px 5px 1px #eee;
  z-index: 10;
}

.subCategoryItem {
  text-align: left;
  vertical-align: bottom;
  padding-left: 5px;
  margin: 2px;
  height: 30px;
  &:hover {
  }
}

.childCategoryArrow {
  float: right;
  margin-right: 10px;
  display: inline-block;
}

.level3Cat {
  margin: 1px;
  position: absolute;
  left: 200px;
  top: 0px;
  width: 200px;
  height: 200px;
  background-color: #fff;
  z-index: 10;
  box-shadow: -2px 1px 5px 1px #eee;
}

.level3CatItem {
  position: relative;
  padding-left: 10px;
  padding-top: 4px;
  height: 30px;
  &:hover {
    background-color: #eee;
  }
}

.categoryWrapper {
  height: auto;
  display: inline-block;
}

.category {
  font-family: Arial,sans-serif !important;
  text-transform: uppercase;
  font-size: 13px;
  min-width: 50px;
  height: 25px;
  box-sizing: content-box;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
}

.search {
  margin-top: 5px;
  width: 100%;
  font-family: Arial,sans-serif !important;
  font-size: 12px;
}

.topSpacer {
  margin-top: 15px;
  vertical-align: bottom;
  line-height: 20px;
}

.searchResults {
  overflow: auto;
  position: absolute;
  top: 39px;
  left: -10px;
  width: 600px;
  max-height: 300px;
  min-height: 100px;
  background: #fff;
  border: 1px solid #ddd;
  z-index: 20;
  box-sizing: border-box;
  margin: 6px;

  @media only screen and (max-width: 575px) {
    left: 0;
    top: 42px;
    width: 100%;
    position: absolute;
    z-index:101;
  }
}

.searchNotFound {
  font-size: 14px;
  padding-left: 5px;
  height: 25px;
}

.categoryTitle {
  font-weight: bold;
  padding-left: 10px;
  padding-top: 3px;
}

.searchCategory {
  height: 30px;
  border-bottom:1px solid #ddd;
}

.resultItem {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #ddd;

  &:hover {
    cursor: pointer;
  }
}

.resultText {
  font-size: 18px;
  color: #000;
  margin-left: 10px;
  top:5px;
  position: relative;
  padding-top: 10px;
  display: inline-block;
  overflow: hidden;
  height: 40px;
  width: 80%;
}

.resultItemName {
  width: 90%;
  float: left;
  overflow: hidden;
  &:hover {
    text-decoration: underline;
  }
}

.resultPrice {
  font-size: 12px;
  float: right;
}

.resultImg {
  display: inline-block;
  margin-left: 10px;
  top: -10px;
  position: relative;
  border: 1px solid #ccc;
  img {
    overflow: hidden;
    height: auto;
    width: 40px;
    max-height: 40px;
    position: relative;
  }
}
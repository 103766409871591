
.remove {
  width: auto;
  padding:0;
  margin:0;
  &:hover {
    cursor: pointer;
  }
}

.inner {
  padding: 5px;
}

.paymentOpt {
  min-height: 50px;
  width: 100%;
  border-bottom: 1px solid var(--color3);

}

.paymentContainer{
  padding: 0px;
  border: 1px solid var(--color3);
}

.paypalArea {
  max-width: 200px;
}

.stripeBtn {

}
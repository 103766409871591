
.footer {
  clear: both;
  text-align: left;
  padding-top: 25px;
  padding: 20px;
  padding-left: 50px;
  padding-right: 50px;
  min-height: 80px;
  color: #002d04;
  background-color: #f0f1f2 !important;
  opacity: 90%;
  margin-top: 25px;
  width: 100%;
  display: block;
}

.signUpBtn {
  margin-top: 10px;
  float: right;
}
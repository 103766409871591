.orderItem {
  margin: 5px;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.borderBottomNotLast:not(:last-child) {
  border-bottom: 1px solid #ccc; 
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.borderBottom {
  border-bottom: 1px solid #ccc; 
  padding: 5px;
  margin: 5px;
}


.smallText {
  font-size: 14px;
}

.greyBg {
  background: #f6f6f6
}
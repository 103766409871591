:root {
  --font-size-base: 16px;
  --font-size-h1: 28px;
  --font-size-h2: 24px;
  --font-size-h3: 20px;
  --font-size-h4: 16px;
  --font-size-h5: 14px;

  --color1: #002d04; 
  --color2: #0cb577;
  --color3: #014d4e;
}

.cartHoverWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  
}

.itemWrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
}

.img {
  display: inline-block;
  text-align: center;
  width: 30%;
  padding: 5px;
  vertical-align: top;
  margin-top:20px;
}

.name {
  font-weight: bold;
}

.details {
  top: 15px;
  display: inline-block;
  width: 50%;
  position: relative;
  font-size: .8rem;

}

.price {
  display: inline-block;  
  width: 18%;
  text-align: right;
  position: relative;
  font-size: .8rem;
  vertical-align: top;
  margin-top:25px;
}

